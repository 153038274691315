import * as React from "react";
import ResizeObserver from "resize-observer-polyfill";
export function useMeasure(ref) {
    var _a = React.useState({
        left: 0,
        top: 0,
        width: 0,
        height: 0
    }), bounds = _a[0], setBounds = _a[1];
    var observer = React.useState(function () {
        return new ResizeObserver(function (_a) {
            var entry = _a[0];
            setBounds(entry.contentRect);
        });
    })[0];
    React.useEffect(function () {
        if (ref.current)
            observer.observe(ref.current);
        return function () { return observer.disconnect(); };
    }, []);
    return bounds;
}
