import * as tslib_1 from "tslib";
import * as React from "react";
import GestureView from "react-page-controller";
import { ArrowRight, ArrowLeft } from "./Icons";
import { Indicators } from "./Indicators";
export function Gallery(_a) {
    // optionally allow the user to pass in an object
    // with an index and animated property. This allows
    // the user to skip to an index without animating.
    var providedIndex = _a.index, onRequestChange = _a.onRequestChange, _b = _a.enableKeyboard, enableKeyboard = _b === void 0 ? true : _b, _c = _a.enableIndicators, enableIndicators = _c === void 0 ? true : _c, _d = _a.enableControls, enableControls = _d === void 0 ? true : _d, children = _a.children, other = tslib_1.__rest(_a, ["index", "onRequestChange", "enableKeyboard", "enableIndicators", "enableControls", "children"]);
    var _e = typeof providedIndex === "number"
        ? { immediate: false, index: providedIndex }
        : providedIndex, immediate = _e.immediate, index = _e.index;
    function onKeyDown(e) {
        // left
        if (e.keyCode === 37) {
            if (index > 0) {
                onRequestChange(index - 1);
                return true;
            }
            // right
        }
        else if (e.keyCode === 39) {
            if (index < totalChildren - 1) {
                onRequestChange(index + 1);
                return true;
            }
        }
        return false;
    }
    React.useEffect(function () {
        if (enableKeyboard) {
            window.addEventListener("keydown", onKeyDown);
        }
        return function () { return window.removeEventListener("keydown", onKeyDown); };
    }, [index]);
    var totalChildren = React.Children.count(children);
    var hasPrev = index > 0;
    var hasNext = index < totalChildren - 1;
    var timerRef = React.useRef(null);
    var _f = React.useState(false), showControls = _f[0], setShowControls = _f[1];
    var _g = React.useState(false), hover = _g[0], setHover = _g[1];
    /**
     * Enable 'hover' based controls
     */
    function onMouseMove() {
        if (!showControls) {
            setShowControls(true);
        }
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(function () {
            setShowControls(false);
        }, 2000);
        return function () {
            clearTimeout(timerRef.current);
        };
    }
    function onEnter() {
        setHover(true);
    }
    function onLeave() {
        setHover(false);
    }
    return (React.createElement("div", tslib_1.__assign({ onMouseMove: onMouseMove, className: "GestureGallery", style: {
            position: "relative",
            width: "100%",
            height: "100%"
        } }, other),
        React.createElement(GestureView, { enableMouse: true, focusOnChange: true, style: {
                width: "100%",
                height: "100%"
            }, value: providedIndex, onRequestChange: onRequestChange, lazyLoad: true, onSetLazy: function (i) {
                var indexes = [i];
                if (i > 0) {
                    indexes.unshift(i - 1);
                }
                if (i < totalChildren) {
                    indexes.push(i + 1);
                }
                return indexes;
            } }, children),
        enableControls && (React.createElement(React.Fragment, null,
            React.createElement(NavigationArrow, { "aria-label": "Show previous image", hidden: !hasPrev, onEnter: onEnter, onLeave: onLeave, visible: (hover || showControls) && hasPrev, onClick: function () {
                    onRequestChange(index - 1);
                }, style: {
                    left: "1rem"
                } },
                React.createElement(ArrowLeft, null)),
            React.createElement(NavigationArrow, { "aria-label": "Show next image", hidden: !hasNext, visible: (hover || showControls) && hasNext, onEnter: onEnter, onLeave: onLeave, onClick: function () {
                    onRequestChange(index + 1);
                }, style: {
                    right: "1rem"
                } },
                React.createElement(ArrowRight, null)))),
        enableIndicators && (React.createElement(Indicators, { visible: true, count: totalChildren, index: index }))));
}
export function NavigationArrow(_a) {
    var hidden = _a.hidden, children = _a.children, label = _a["aria-label"], onClick = _a.onClick, onEnter = _a.onEnter, onLeave = _a.onLeave, visible = _a.visible, style = _a.style;
    return (React.createElement("div", { className: "Gallery__NavigationArrow", "aria-label": label, "aria-hidden": hidden, onMouseEnter: onEnter, onMouseLeave: onLeave, style: tslib_1.__assign({ position: "absolute", top: "50%", transform: "translateY(-50%)", transition: "opacity 0.5s ease", opacity: visible ? 1 : 0, pointerEvents: visible ? "auto" : "none" }, style) },
        React.createElement("button", { onClick: onClick, style: {
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                display: "flex",
                background: "rgba(255,255,255,0.7)",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                border: "none"
            } }, children)));
}
export function GalleryImage(_a) {
    var src = _a.src, alt = _a.alt, srcSet = _a.srcSet, style = _a.style, _b = _a.objectFit, objectFit = _b === void 0 ? "contain" : _b, caption = _a.caption, other = tslib_1.__rest(_a, ["src", "alt", "srcSet", "style", "objectFit", "caption"]);
    function onDragStart(e) {
        e.preventDefault();
    }
    return (React.createElement("div", tslib_1.__assign({ className: "GalleryImage", style: tslib_1.__assign({ flex: 1, display: "flex", overflow: "hidden", height: "100%" }, style) }, other),
        React.createElement("img", { style: {
                maxWidth: "100%",
                height: "auto",
                margin: "0 auto",
                display: "block",
                maxHeight: "100%",
                objectFit: objectFit
            }, onDragStart: onDragStart, src: src, srcSet: srcSet, alt: alt })));
}
